import './short-portfolio.css';
import React from 'react';


function PreWedding () {
  
  

    return ( 
      <div  className='portfolio-component'> 

                      
      <div id= "services" className="services">
                        
          <iframe title='snippet'  src="https://drive.google.com/file/d/1UQNkawQm4BUtMEWsZVfcjF0FIE_poPg4/preview" muted alt='Valor Pictures' className='camera'>Your browser does not support the video tag.</iframe>
              
          <iframe title='snippet'  src="https://drive.google.com/file/d/1UTR9Zca7wSjoE6iceL4mgHlHK9W3HmTS/preview" muted alt='Valor Pictures' className='camera'>Your browser does not support the video tag.</iframe>
            
          <iframe title='snippet'  src="https://drive.google.com/file/d/1UZN7U6WhJs1k_xHHhSESxKBly94yYFG6/preview" muted alt='Valor Pictures' className='camera'>Your browser does not support the video tag.</iframe>
            
          <iframe title='snippet'  src="https://drive.google.com/file/d/1UgGBzq7wFDLwGGiZbOOgA2HO6ajCcD_o/preview" muted alt='Valor Pictures' className='camera'>Your browser does not support the video tag.</iframe>
            
          <iframe title='snippet' src="https://drive.google.com/file/d/1UiZ1OVvVbkRY8TDbDA_eMEdMz-EKnVEz/preview" muted alt='Valor Pictures' className='camera'>Your browser does not support the video tag.</iframe>
            
                                  
      </div> 
      <a href="https://drive.google.com/drive/folders/1CIQ1ty0Znlj04eIw7PoLCZ1Pez8NBVfU?usp=drive_link" className="navbar__link" target="_blank" rel="noreferrer">View More</a>

    </div>  
    );
  }
  
  export default PreWedding;