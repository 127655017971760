import './short-portfolio.css';
import React from 'react';


function Ads () {
  
  

    return ( 
      <div className='portfolio-component'> 

      <div id= "services" className="services">
                      
            <iframe title='snippet' src="https://drive.google.com/file/d/1TDCkOpcCzQqAqYTlbo07Q6xE5E6X7rd_/preview" muted alt='Valor Pictures' className='camera'>Your browser does not support the video tag.</iframe>
                                
            <iframe title='snippet'  src="https://drive.google.com/file/d/1TE0n6_3Q027-GWN8mt8Mj8M8dzZ53CqG/preview" muted alt='Valor Pictures' className='camera'>Your browser does not support the video tag.</iframe>
             
            <iframe title='snippet'  src="https://drive.google.com/file/d/1TFcg3UDiVuZvI_GyJQQaIj1g-T_1RPqr/preview" muted alt='Valor Pictures' className='camera'>Your browser does not support the video tag.</iframe>
             
            <iframe title='snippet'  src="https://drive.google.com/file/d/1TFx8t_u52ZeuB_n2xjRWr4A4WyVf2384/preview" muted alt='Valor Pictures' className='camera'>Your browser does not support the video tag.</iframe>
             
            <iframe title='snippet' src="https://drive.google.com/file/d/1TIFdiXijn9FyqUGUXIlapkFgEqhG0rgw/preview" muted alt='Valor Pictures' className='camera'>Your browser does not support the video tag.</iframe>
             
      </div>   

      <a href="https://drive.google.com/drive/folders/1CEgP0mpiEs14B_K1gWsiBI01uOlt2KWJ?usp=drive_link" className="navbar__link" target="_blank" rel="noreferrer">View More</a>
    </div>  
    );
  }
  
  export default Ads;