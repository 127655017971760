import './short-portfolio.css';
import React from 'react';


function Proposals () {
  
  

    return ( 
      <div  className='portfolio-component'> 

        <div id= "services" className="services">
        
            <iframe title='snippet'  src="https://drive.google.com/file/d/1UilExfteoPwiZt1vyDnK3y-NG4rcRWH_/preview" muted alt='Valor Pictures' className='camera'>Your browser does not support the video tag.</iframe>
              
              <iframe title='snippet'  src="https://drive.google.com/file/d/1UplhaR6_gzyDHQOOQGKZkW72vl_IHioj/preview" muted alt='Valor Pictures' className='camera'>Your browser does not support the video tag.</iframe>
                
              <iframe title='snippet'  src="https://drive.google.com/file/d/1Us49Uwe4yMF6vcUsmFCexleFvib_JRBZ/preview" muted alt='Valor Pictures' className='camera'>Your browser does not support the video tag.</iframe>
                
              <iframe title='snippet'  src="https://drive.google.com/file/d/1UtZ9opUgBpyqtcn0HoFTaLhgHBNyVjLe/preview" muted alt='Valor Pictures' className='camera'>Your browser does not support the video tag.</iframe>
                
              <iframe title='snippet' src="https://drive.google.com/file/d/1SnPOXhPatg7wQ-FzfMChYq_A6O3oaCrr/preview" muted alt='Valor Pictures' className='camera'>Your browser does not support the video tag.</iframe>
                
                   
             
        </div>  

        <a href="https://drive.google.com/drive/folders/1CIwnQU4hyYdZzL59QshpHLZLiN2iqAMn?usp=drive_link" className="navbar__link" target="_blank" rel="noreferrer">View More</a>   
      </div>  
    );
  }
  
  export default Proposals;